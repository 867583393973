<template>
  <div id="main" class="main pr">
    <main-header id="main-header" :showNav="showNav" class="top-fixed" :class="{'is-fixed': isFixed || metaFixed}"></main-header>
    <main-content v-loading="!contentVisible"></main-content>
    <el-backtop target="#main"></el-backtop>
  </div>
</template>

<script>
  import MainHeader from './main-header'
  import MainContent from './main-content'
  import {checkIsNull} from '../utils'
  export default {
    data () {
      return{
        showNav: true,
        contentVisible: false,
        isFixed: false,
        metaFixed: false
      }
    },
    components: {MainContent, MainHeader},
    computed: {
      loginStatus: {
        get () { return this.$store.state.user.loginStatus },
        set (val) { this.$store.commit('user/updateLoginStatus', val) }
      },
      userId: {
        get () { return this.$store.state.user.id },
        set (val) { this.$store.commit('user/updateId', val) }
      },
      name: {
        get () { return this.$store.state.user.name },
        set (val) { this.$store.commit('user/updateName', val) }
      },
      email: {
        get () { return this.$store.state.user.email },
        set (val) { this.$store.commit('user/updateEmail', val) }
      },
      userName: {
        get () { return this.$store.state.user.userName },
        set (val) { this.$store.commit('user/updateUserName', val) }
      },
      mobile: {
        get () { return this.$store.state.user.mobile },
        set (val) { this.$store.commit('user/updateMobile', val) }
      },
      orgCode: {
        get () { return this.$store.state.user.orgCode },
        set (val) { this.$store.commit('user/updateOrgCode', val) }
      },
      orgName: {
        get () { return this.$store.state.user.orgName },
        set (val) { this.$store.commit('user/updateOrgName', val) }
      },
      accountType: {
        get () { return this.$store.state.user.accountType },
        set (val) { this.$store.commit('user/updateAccountType', val) }
      },
      info: {
        get () { return this.$store.state.user.info },
        set (val) { this.$store.commit('user/updateInfo', val) }
      },
      unRead: {
        get () { return this.$store.state.user.unRead },
        set (val) { this.$store.commit('user/updateUnRead', val) }
      },
      registerTime: {
        get () { return this.$store.state.user.registerTime },
        set (val) { this.$store.commit('user/updateRegisterTime', val) }
      },
      userDiscountEntityList: {
        get () { return this.$store.state.user.userDiscountEntityList },
        set (val) { this.$store.commit('user/updateUserDiscountEntityList', val) }
      },
      userHead: {
        get () { return this.$store.state.user.userHead },
        set (val) { this.$store.commit('user/updateUserHead', val) }
      }
    },
    created () {
      // 根据token判断是否登录中
      const token = this.$cookie.get('marketToken')
      if (!checkIsNull(token)) {
        this.loginStatus = true
      }
    },
    mounted () {
      window.addEventListener('scroll', this.handleScroll)
      // 如果已登录--重新获取当前登录用户信息
      if (this.loginStatus) {
        this.getUserInfo()
      } else {
        this.contentVisible = true
      }
    },
    watch: {
      $route(val){
        // if(val.meta.headerFixed)
          this.metaFixed = val.meta.headerFixed || false
        // console.log(this.isFixed)
      }
    },
    methods: {
      handleScroll () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 10) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      },
      // 获取当前登录用户信息
      getUserInfo () {
        this.contentVisible = false
        this.$http({
          url: this.$http.adornUrl(this.$api.SYS.USER.INFO),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.userId = data.user.userId
            this.name = data.user.name
            this.userName = data.user.username
            this.email = data.user.email
            this.mobile = data.user.mobile
            this.orgCode = data.user.orgCode
            this.orgName = data.user.orgName
            this.accountType = data.user.accountType
            this.registerTime = data.user.createTime
            this.unRead = data.user.unRead
            if (data.user.headPath) {
              this.userHead = data.user.headPath
            }
            // this.info = data.user.userInfo || []
            this.userDiscountEntityList = data.user.userDiscountEntityList || []
            this.getRelationEnterprise()
          }
          this.contentVisible = true
        })
      },
      getRelationEnterprise () {
        this.$http({
          url: this.$http.adornUrl(this.$api.SYS.USER.RELATION_ENTERPRISE),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.info = data.data
          }
        })
      }
    }
  }
</script>

<style scoped>
 #main {
   font-family: "Avenir", Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   /*text-align: center;*/
 }
 .top-fixed {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999
 }
 .is-fixed {
   background: rgba(0,0,0,.4);
   -webkit-backdrop-filter: blur(6px);
   backdrop-filter: blur(6px);
 }
 .right-fixed {
   position: fixed;
   right: 0;
   bottom: 100px;
   width: 50px;
   min-height: 40px;
   display: flex;
   box-shadow: 0 0 6px rgba(0,0,0,.12);
   z-index: 5;
   background: #FFFFFF;
   cursor: pointer;
 }
 .right-fixed .chat {
   width: 100%;
   height: 50px;
 }
 .right-fixed .chat:hover {
   background: #F2F6FC;
 }
 .right-fixed .chat .iconfont {
   font-size: 20px;
   color: #00c2c9;
 }
 .main {
   width: 100vw;
   min-height: 400px;
 }
</style>
